<template>
  <div
    v-if="showVerifiedMark"
    @click.stop>
    <component
      :is="validPage ? 'router-link' : 'span'"
      class="verified-circle-container sourcery__icon-wrapper"
      :class="[isChildVerified ? 'red darken-2' : 'green']"
      :to="routerToParams"
      target="_blank">
      <img src="@/assets/icons/verified-icon.svg">
    </component>
    <component
      :is="validPage ? 'router-link' : 'span'"
      v-if="shouldShowRollover"
      class="rollover-container"
      :style="{ 'transform': `translate(-50%, -200%) translateX(${size/2}px)`}"
      :to="routerToParams"
      target="_blank">
      <v-chip
        class="fs-14"
        :color="isChildVerified ? 'red darken-2' : 'green'"
        text-color="white">
        <span>
          {{ pageNameText }}
        </span>
      </v-chip>
    </component>
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { ROUTE_WORKSPACE_PAGE } from '@/constants';
export default {
  name: 'VerifiedMark',
  props: {
    isVerified: {
      type: Boolean,
      default: false,
    },
    childVerifiedWs: {
      type: String,
      default: null,
    },
    verifiedPageId: {
      type: String,
      default: null,
    },
    verifiedPageName: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 24,
    },
    // Remove this once we can remove the feature flag 'optimizationsVerifiedWorkspace'
    ignoreFeatureFlag: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('FeatureFlags', ['optimizationsVerifiedWorkspaces']),
    showVerifiedMark() {
      if (!this.isVerified && !this.isChildVerified) return false;

      return this.optimizationsVerifiedWorkspaces || this.ignoreFeatureFlag;
    },
    shouldShowRollover() {
      return this.showVerifiedMark && this.validPage;
    },
    isChildVerified() {
      return this.childVerifiedWs && this.childVerifiedWs === this.activeWorkspaceId;
    },
    validPage() {
      return this.verifiedPageName && this.verifiedPageId;
    },
    pageNameText() {
      return this.verifiedPageName?.toUpperCase();
    },
    routerToParams() {
      return {
        name: ROUTE_WORKSPACE_PAGE,
        params: {
          pageId: this.verifiedPageId,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .rollover-container {
    display: none;
    position: absolute;
    &:hover {
      display: block;
      cursor: pointer;
    }
  }
  .verified-circle-container:hover + .rollover-container {
    display: block;
  }
  // The main app.scss file has important for these...why?
  // - So we are forced to do !important here.
  ::v-deep .v-chip {
    height: 24px !important;
    border: none !important;
  }
  ::v-deep .v-chip__content {
    max-width: 200px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
</style>
