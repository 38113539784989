import Vue from 'vue';
const isVueAppInstance = ctx => ctx instanceof Vue;
const watchBy = (watchProperty, watchCallback, ctx) => {
  if (!isVueAppInstance(ctx)) throw new Error('wrong context provided');
  const watchInstance = ctx.$watch(watchProperty, (newVal, oldVal) => watchCallback(newVal, watchInstance, oldVal));
};
export {
  watchBy,
  isVueAppInstance,
};
